<template>
  <div class="w-full max-w-64 rounded-md shadow-md overflow-hidden">
    <!-- Image/slider at the top -->
    <div class="overflow-hidden">
      <router-link
        :to="`/properties/for-sale/${details.id}/${details.slug}`"
        class="block w-full h-40"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          loading="lazy"
          class="object-cover w-full h-full transition-transform duration-300 transform hover:scale-110"
          :src="imageSrc"
          alt=""
        />
      </router-link>
    </div>

    <!-- Basic information: property type, price, age -->
    <div class="pt-4 pb-4 px-3">
      <div class="text-xs font-bold uppercase text-gray-500">
        {{ details.investment_type.name }}
      </div>
      <div class="text-2xl text-gray-600 font-medium">
        &#8358;{{ Number(details.price).toLocaleString() }}
      </div>
      <div class="mt-1 text-grey-600 text-sm font-medium">
        {{ details.location.locality }}
      </div>
    </div>

    <div class="p-3 border-t-2 border-gray-100 text-sm text-grey-600">
      <div v-if="isLand">
        {{ Number(details.lot_size).toLocaleString() }} sqm
        &#32;&middot;&#32;
        <span class="capitalize">{{ details.land_texture }}</span>
      </div>

      <div v-else class="w-full flex">
        <div class="w-1/2 flex items-center" style="font-size: 0.8rem;">
          <BedIcon class="w-5 h-auto mr-2" />
          <span class="font-bold mr-1">{{ details.beds }}</span>
          bedroom<span v-if="details.beds > 0">s</span>
        </div>

        <div class="w-1/2 flex items-center" style="font-size: 0.8rem;">
          <BathroomIcon class="w-5 h-auto mr-2" />
          <span class="font-bold mr-1">{{ details.baths }}</span>
          bathroom<span v-if="details.baths > 0">s</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BathroomIcon from '@/components/icons/BathroomIcon';
import BedIcon from '@/components/icons/BedIcon';

export default {
  components: { BathroomIcon, BedIcon },
  props: {
    details: {
      type: Object,
      required: true,
    },
  },

  computed: {
    imageSrc () {
      const cdnPrefix = process.env.VUE_APP_CDN_PREFIX;

      return `${cdnPrefix}/tr:q-70,w-600/${this.details.cover_image.path}`;
    },

    isLand () {
      return this.details.investment_type.slug === 'land';
    }
  }
};
</script>
