<template>
  <div class="">
    <div class="max-w-screen-xl px-4 sm:px-6 mx-auto mb-20 mt-6">
      <!-- filter (search) bar -->
      <div class="bg-gray-100 rounded-lg pt-1 pb-3 px-4 lg:px-6">
        <div
          @click="isFilterMenuOpen = !isFilterMenuOpen"
          class="py-2 flex sm:hidden justify-between"
        >
          <div class="block leading-5 font-medium text-gray-700">Filters</div>
          <div>
            <svg
              class="-mr-1 ml-2 h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>

        <transition
          enter-active-class="transition ease-out duration-100 transform"
          enter-class="opacity-0 scale-95"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75 transform"
          leave-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-95"
        >
          <div
            :class="{ hidden: !isFilterMenuOpen, flex: isFilterMenuOpen }"
            class="flex-col flex-wrap sm:flex-row pt-3 sm:pt-0 sm:flex"
          >
            <!-- property type -->
            <div class="w-full sm:w-40 my-3 mr-5">
              <label
                for="propertyType"
                class="block text-sm leading-5 font-medium text-gray-700"
                >Property Type</label
              >
              <select
                v-model="investmentType"
                id="propertyType"
                class="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
              >
                <option selected value=""> Any </option>
                <option>Land</option>
                <option>House</option>
              </select>
            </div>

            <!-- location -->
            <div class="w-full sm:w-40 my-3 mr-5">
              <label
                for="location"
                class="block text-sm leading-5 font-medium text-gray-700"
                >Location</label
              >
              <select
                v-model="location"
                id="location"
                class="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
              >
                <option selected value=""> Any </option>
                <option
                  v-for="(l, index) in $store.state.locations"
                  :value="l"
                  :key="index"
                >
                  {{ l }}
                </option>
              </select>
            </div>

            <!-- price dropdown -->
            <div class="w-full sm:w-auto my-3 mr-5 flex flex-col">
              <label class="block text-sm leading-5 font-medium text-gray-700"
                >Price range</label
              >
              <div class="flex items-center">
                <div class="w-full relative">
                  <div
                    class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                  >
                    <span class="text-gray-500 sm:text-sm sm:leading-5 mt-1">
                      &#8358;
                    </span>
                  </div>
                  <select
                    v-model="price.min"
                    id="min_price"
                    class="mt-1 form-select block w-full pl-6 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                  >
                    <option selected :value="null"> 0 </option>
                    <option value="500000">500,000</option>
                    <option value="600000">600,000</option>
                    <option value="700000">700,000</option>
                    <option value="800000">800,000</option>
                    <option value="900000">900,000</option>
                    <option value="1000000">1 Million</option>
                    <option value="2000000">2 Million</option>
                    <option value="3000000">3 Million</option>
                    <option value="5000000">5 Million</option>
                  </select>
                </div>
                <span class="mx-3"> to </span>
                <div class="w-full relative">
                  <div
                    class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
                  >
                    <span class="text-gray-500 sm:text-sm sm:leading-5 mt-1">
                      &#8358;
                    </span>
                  </div>
                  <select
                    v-model="price.max"
                    id="max-price"
                    class="mt-1 form-select block w-full pl-6 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                  >
                    <option selected :value="null"> Infinity </option>
                    <option value="1000000">1 Million</option>
                    <option value="2000000">2 Million</option>
                    <option value="3000000">3 Million</option>
                    <option value="5000000">5 Million</option>
                    <option value="10000000">10 Million</option>
                    <option value="20000000">20 Million</option>
                    <option value="40000000">40 Million</option>
                    <option value="60000000">60 Million</option>
                    <option value="80000000">80 Million</option>
                    <option value="100000000">100 Million+</option>
                  </select>
                </div>
              </div>
            </div>

            <!-- beds/baths -->
            <template v-if="investmentType === 'House'">
              <div class="w-full sm:w-40 my-3 mr-5">
                <label
                  for="beds"
                  class="block text-sm leading-5 font-medium text-gray-700"
                  >Beds</label
                >
                <select
                  v-model="beds"
                  id="beds"
                  class="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                >
                  <option selected :value="null"> --- </option>
                  <option> 1 </option>
                  <option> 2 </option>
                  <option> 3 </option>
                  <option> 4 </option>
                  <option value="5"> 5+ </option>
                </select>
              </div>
              <div class="w-full sm:w-40 my-3 mr-5">
                <label
                  for="baths"
                  class="block text-sm leading-5 font-medium text-gray-700"
                  >Baths</label
                >
                <select
                  v-model="baths"
                  id="baths"
                  class="mt-1 form-select block w-full pl-3 pr-10 py-2 text-base leading-6 border-gray-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5"
                >
                  <option selected :value="null"> --- </option>
                  <option> 1 </option>
                  <option> 2 </option>
                  <option> 3 </option>
                  <option> 4 </option>
                  <option value="5"> 5+ </option>
                </select>
              </div>
            </template>

            <!-- filter button -->
            <div
              class="w-full sm:w-40 my-3 mr-5 flex flex-col justify-end items-end sm:items-start"
            >
              <span class="inline-flex rounded-md shadow-sm">
                <button
                  @click.prevent="filter"
                  type="button"
                  class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:border-gray-700 focus:shadow-outline-gray active:bg-gray-700 transition ease-in-out duration-150"
                >
                  Filter Results
                </button>
              </span>
            </div>
          </div>
        </transition>
      </div>

      <div class="mt-10">
        <!-- search list -->
        <div v-if="loadingResults">
          <PageSpinner />
        </div>
        <div v-show="listings.length === 0" class="pt-4 md:pt-10">
          <div class="flex justify-center">
            <svg
              class="h-32 w-auto text-gray-500 md:h-40"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
              />
            </svg>
          </div>
          <div class="flex justify-center py-6">
            <div>
              <p
                class="max-w-lg text-gray-700 text-base text-center sm:text-lg"
              >
                Sorry, we couldn't find anything matching those search terms.
                Please use different terms, and try again.
              </p>
            </div>
          </div>
        </div>
        <div v-show="listings.length > 0">
          <!-- header -->
          <div class="my-5 font-heading-sans text-xl sm:text-2xl">
            Showing {{ listings.length }} result{{
              listings.length === 1 ? '' : 's'
            }}
          </div>

          <div
            class="mt-6 grid grid-flow-row gap-10 gap-y-12 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-y-20"
          >
            <Listing
              v-for="listing in listings"
              :key="listing.id"
              :details="listing"
            />
          </div>
        </div>
      </div>
    </div>

    <Contact />
  </div>
</template>

<script>
import Listing from '@/components/listings/Listing';
import Contact from '@/components/common/Contact';
import PageSpinner from '@/components/PageSpinner';

export default {
  components: { Listing, Contact, PageSpinner },

  head() {
    const { length } = this.listings;
    if (!length) return;

    const title = 'Properties for Sale in Lagos';
    const description = `Browse ${length} properties currently listed for sale in Lagos. Buy securely with Roofone.`;

    return {
      title,
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: title,
        },
        {
          vmid: 'description',
          name: 'description',
          content: description,
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: description,
        },
      ],
    };
  },
  data() {
    return {
      isFilterMenuOpen: true,
      price: {
        min: null,
        max: null,
      },
      beds: null,
      baths: null,
      investmentType: '',
      location: '',
      listings: [],
      loadingResults: true,
    };
  },

  serverPrefetch() {
    return this.search();
  },

  mounted() {
    if (!this.listings.length) {
      const self = this;
      this.search().then(() => {
        self.filter();
        self.loadingResults = false;
      });
      this.getLocationsList();
    }
  },

  methods: {
    search() {
      return this.$store.dispatch('searchListings');
    },

    getLocationsList() {
      if (this.$store.state.locations === null) {
        return this.$store.dispatch('getLocations');
      }
      return;
    },

    filter() {
      const { investmentType, price, location, beds, baths } = this;
      const filterOptions = { investmentType, price, location, beds, baths };

      this.listings = this.$store.getters.filterResults(filterOptions);
      return;
    },
  },
};
</script>
