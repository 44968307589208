<template>
  <svg
    version="1.1"
    viewBox="0 0 232 144"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M216 64.4067v-28.4067c0-19.8516-16.1621-36-36.0293-36h-127.941c-19.8672 0-36.0293 16.1484-36.0293 36v28.4067c-9.1191 1.868-16 9.9595-16 19.6324v23.9609c0 2.2109 1.791 4 4 4h4v8 24h8v-20h200v20h8v-24 -8h4c2.209 0 4-1.7891 4-4v-23.9609c0-9.6729-6.8809-17.7644-16-19.6324Z"
      fill="#535353"
    ></path>
    <path
      fill="#FFF"
      d="M24 36c0-15.4375 12.5742-28 28.0293-28h127.941c15.4551 0 28.0293 12.5625 28.0293 28v28h-20v-24c0-1.6367-.9961-3.1055-2.5137-3.7148 -.8457-.336-20.8379-8.2852-31.4863-8.2852 -10.6484 0-30.6406 7.9492-31.4863 8.2852 -1.5176.6093-2.5137 2.0781-2.5137 3.7148v24h-8v-24c0-1.6367-.9961-3.1055-2.5137-3.7148 -.8457-.336-20.8379-8.2852-31.4863-8.2852 -10.6484 0-30.6406 7.9492-31.4863 8.2852 -1.5176.6093-2.5137 2.0781-2.5137 3.7148v24h-20v-28Z"
    ></path>
    <g fill="#D8D8D8">
      <path
        d="M180 64h-52v-21.2578c6.6953-2.5039 19.3945-6.7422 26-6.7422 6.5957 0 19.3008 4.2383 26 6.7461v21.2539Z"
      ></path>
      <path
        d="M104 64h-52v-21.2578c6.6953-2.5039 19.3945-6.7422 26-6.7422 6.5957 0 19.3008 4.2383 26 6.7461v21.2539Z"
      ></path>
      <rect width="200" height="4" x="16" y="112"></rect>
      <path
        d="M224 104h-4 -208 -4v-19.9609c0-6.6368 5.3926-12.0391 12.0195-12.0391h27.9805 60 16 60 27.9805c6.6269 0 12.0195 5.4023 12.0195 12.0391v19.9609Z"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
